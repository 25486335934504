import React from "react";
import { useParams } from "react-router-dom";
import { Board } from "../../components/Board/Board";
import { LayersPanel } from "../../components/Layers";
import { ToolsPanel } from "../../components/ToolsPanel";
import "./Board.scss";

export interface BoardPageParams {
  id: string;
}

export const BoardPage: React.FunctionComponent = () => {
  const { id } = useParams<BoardPageParams>();

  return (
    <div className="board">
      <ToolsPanel />
      <Board id={id} />
      <LayersPanel />
    </div>
  );
};
