import React from "react";
import "./App.scss";
import { Auth } from "./components/Auth";
import { BoardPage } from "./pages/Board";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { PasswordResetPage } from "./pages/PasswordReset";
import { BotBoardPage } from "./pages/Board/BotBoard";

export const App: React.FunctionComponent = () => {
  return (
    <div className="App">
      <Router>
        <Route path="/reset/:token" component={PasswordResetPage} />
        <Auth>
          <Route path="/board/:id" component={BoardPage} />
          <Route path="/botboard/:id" component={BotBoardPage} />
        </Auth>

        <Route path="/" exact>
          <Redirect to="/board/default" />
        </Route>
      </Router>
    </div>
  );
};
