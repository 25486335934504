import React, { useEffect, useState } from "react";
import cs from "clsx";

import { BrushPanel } from "./Tools/Brush";
import "./ToolsPanel.scss";
import { BrushTool, EraserTool, Tool as BoardTool } from "../board";
import { useStore } from "../store";
import { EraserPanel } from "./Tools/Eraser";

export interface ToolsPanelProps {}

export type Tools = "brush" | "eraser";

export interface Tool {
  id: Tools;
  name: string;
  component: React.FunctionComponent;
  tool: BoardTool;
}

export const ToolList: Tool[] = [
  { name: "Brush", id: "brush", component: BrushPanel, tool: new BrushTool() },
  {
    name: "Eraser",
    id: "eraser",
    component: EraserPanel,
    tool: new EraserTool(),
  },
];

const getTool = (name: Tools): Tool | undefined => {
  return ToolList.find((t) => t.id === name);
};

export const ToolsPanel: React.FunctionComponent = () => {
  const board = useStore((state) => state.board);
  const [selectedTool, setSelectedTool] = useState<Tools>("brush");

  useEffect(() => {
    if (!board) {
      return;
    }
    const tool = ToolList.find((t) => t.id === selectedTool);
    board.setTool(tool?.tool);
  }, [selectedTool, board]);

  const ToolPanel = getTool(selectedTool)?.component;

  return (
    <>
      <div className="tools-panel">
        {ToolList.map((t) => (
          <div
            key={t.id}
            className={cs("tool", { selected: t.id === selectedTool })}
            onClick={() => setSelectedTool(t.id)}
          >
            <span>{t.name}</span>
          </div>
        ))}
      </div>
      {ToolPanel && <ToolPanel />}
    </>
  );
};
