import Konva from "@greyboard/konva";

import { v4 as uuid } from "uuid";

import { LineConfig } from "@greyboard/konva/lib/shapes/Line";
import { Cursor, Tool, Board, Point, BrushShapes } from ".";
import { ToolSettings } from "./board";

export interface BrushSettings {
  colour: string;
  strokeWidth: number;
  opacity: number;
}

export class BrushTool implements Tool {
  globalCompositeOperation: LineConfig["globalCompositeOperation"] =
    "source-over";
  shapeType: BrushShapes["type"] = "brush-stroke";
  settingsIndex: keyof ToolSettings = "brush";

  board?: Board;
  cursor: BrushCursor;
  isDrawing: boolean;
  brushSettings?: BrushSettings;
  currentShape?: BrushShapes;
  currentLine?: Konva.Line;

  constructor() {
    this.cursor = new BrushCursor();
    this.isDrawing = false;
    this.brushSettings = { colour: "#000", strokeWidth: 20, opacity: 1 };
  }

  init(board: Board, toolSettings: ToolSettings) {
    this.board = board;
    this.cursor.init(board, board.pointerLayer);
    this.brushSettings = { ...toolSettings[this.settingsIndex] };
    this.cursor.setRadius(this.brushSettings.strokeWidth / 2);

    board.stage.on("pointerdown.brushdrawing", this.drawingDown.bind(this));
    board.stage.on("pointermove.brushdrawing", this.drawingMove.bind(this));
    board.stage.on("pointerup.brushdrawing", this.drawingUp.bind(this));
    board.container.addEventListener("pointerleave", this.drawingLeave);
  }

  deinit(board: Board) {
    this.cursor.deinit(board, board.pointerLayer);
    board.stage.off(
      "pointerdown.brushdrawing pointermove.brushdrawing pointerup.brushdrawing",
    );
    board.container.removeEventListener("pointerleave", this.drawingLeave);
    this.board = undefined;
  }

  updateToolSettings(toolSettings: ToolSettings) {
    this.brushSettings = { ...toolSettings[this.settingsIndex] };
    this.cursor.setRadius(this.brushSettings.strokeWidth / 2);
  }

  drawingLeave = () => {
    if (!this.isDrawing) {
      return;
    }
    this.endDrawing();
  };

  sendColourUsed(colour: string) {
    this.board?.emit("colourUsed", colour);
  }

  drawingDown(e: Konva.KonvaEventObject<PointerEvent>) {
    if (!this.board || !this.brushSettings || e.evt.button !== 0) {
      return;
    }

    const layer = this.board.getSelectedKonvaLayer();
    if (!layer) {
      return;
    }

    this.sendColourUsed(this.brushSettings.colour);

    this.isDrawing = true;
    const pos = this.board.stage.getRelativePointerPosition();

    const currentShape: BrushShapes = {
      id: uuid(),
      type: this.shapeType,
      path: [],
      props: {
        cap: "round",
        colour: this.brushSettings.colour,
        width: this.brushSettings.strokeWidth,
        opacity: this.brushSettings.opacity,
      },
    };
    this.currentShape = currentShape;

    this.currentLine = new Konva.Line({
      stroke: this.currentShape.props.colour,
      strokeWidth: this.currentShape.props.width,
      globalCompositeOperation: this.globalCompositeOperation,
      points: [],
      tension: 0.5,
      lineCap: this.currentShape.props.cap,
      opacity: this.brushSettings.opacity,
    });
    this.addStrokePoint(pos);
    layer.add(this.currentLine);

    const blayer = this.board.getSelectedLayer();
    if (blayer) {
      this.board.emit("drawingStart", this.currentShape, blayer);
    }

    this.board.stage.batchDraw();
  }

  drawingMove() {
    if (!this.board || !this.isDrawing) {
      return;
    }
    const pos = this.board.stage.getRelativePointerPosition();
    this.addStrokePoint(pos);

    const blayer = this.board.getSelectedLayer();
    if (blayer && this.currentShape) {
      this.board.emit("drawingUpdate", this.currentShape, blayer);
    }

    const layer = this.board.getSelectedKonvaLayer();
    layer?.batchDraw();
    // this.board.stage.batchDraw();
  }

  drawingUp() {
    if (!this.isDrawing) {
      return;
    }
    this.endDrawing();
  }

  endDrawing() {
    if (
      !this.board ||
      !this.isDrawing ||
      !this.currentLine ||
      !this.currentShape
    ) {
      return;
    }

    const layer = this.board.selectedLayer;
    if (!layer) {
      return;
    }

    if (this.currentShape.path.length === 1) {
      this.currentShape.path.push(this.currentShape.path[0]);
    }

    const debugArray = [];
    let lastPoint: Point | undefined;
    for (const point of this.currentShape.path) {
      if (lastPoint) {
        debugArray.push(this.pointDistance(lastPoint, point));
      }
      lastPoint = point;
    }
    console.log("distances", debugArray);

    this.board.addShape(layer, this.currentShape);
    this.currentLine.destroy();
    this.isDrawing = false;

    const blayer = this.board.getSelectedLayer();
    if (blayer) {
      this.board.emit("drawingEnd", this.currentShape, blayer);
    }

    this.board.stage.batchDraw();
  }

  pointDistance(point1: Point, point2: Point) {
    const a = point1.x - point2.x;
    const b = point1.y - point2.y;
    const distance = Math.sqrt(a * a + b * b);
    return distance;
  }

  addStrokePoint(point: Point) {
    if (!this.currentLine || !this.currentShape) {
      return;
    }
    let lastPointTooClose = false;
    let secondLastPointTooClose = false;
    const lastPoint = this.currentShape.path[this.currentShape.path.length - 1];
    const secondLastPoint =
      this.currentShape.path[this.currentShape.path.length - 2];
    if (lastPoint) {
      const distance = this.pointDistance(point, lastPoint);
      // console.log("1", distance);
      if (distance < 0.1 * (this.brushSettings?.strokeWidth || 1)) {
        // this.currentShape.path = [
        //   ...this.currentShape.path.slice(0, -1),
        //   point,
        // ];
        // this.currentLine.points(
        //   this.currentShape.path.flatMap(({ x, y }) => [x, y]),
        // );
        // return;
        lastPointTooClose = true;
      }
    }
    if (secondLastPoint) {
      const distance = this.pointDistance(point, secondLastPoint);
      // console.log("2", distance);
      if (distance < 0.1 * (this.brushSettings?.strokeWidth || 1)) {
        // this.currentShape.path = [
        //   ...this.currentShape.path.slice(0, -1),
        //   point,
        // ];
        // this.currentLine.points(
        //   this.currentShape.path.flatMap(({ x, y }) => [x, y]),
        // );
        // return;
        secondLastPointTooClose = true;
      }
    }

    if (lastPointTooClose && secondLastPoint) {
      //
    } else if (lastPointTooClose && !secondLastPointTooClose) {
      // this.currentShape.path = [...this.currentShape.path.slice(0, -1), point];
      // this.currentLine.points(
      //   this.currentShape.path.flatMap(({ x, y }) => [x, y]),
      // );
    } else {
      this.currentShape.path = [...this.currentShape.path, point];
      this.currentLine.points(
        this.currentShape.path.flatMap(({ x, y }) => [x, y]),
      );
    }
  }

  scale(scale: number) {
    this.cursor.scale?.(scale);
  }
}

export class EraserTool extends BrushTool {
  constructor() {
    super();
    this.shapeType = "eraser-stroke";
    this.globalCompositeOperation = "destination-out";
    this.settingsIndex = "eraser";
  }

  sendColourUsed(_colour: string) {}
}

export class BrushCursor implements Cursor {
  stage?: Konva.Stage;
  layer?: Konva.Layer;
  container?: HTMLDivElement;
  visible: boolean;
  circle: Konva.Circle;
  outerCircle: Konva.Circle;

  enterHandler: () => void;
  leaveHandler: () => void;

  constructor() {
    this.circle = new Konva.Circle({
      radius: 10,
      x: 100,
      y: 100,
      stroke: "#000000",
      strokeWidth: 1,
    });
    this.outerCircle = new Konva.Circle({
      radius: 12,
      x: 100,
      y: 100,
      stroke: "#ffffff",
      strokeWidth: 1,
    });
    this.visible = true;
    this.enterHandler = this.enter.bind(this);
    this.leaveHandler = this.leave.bind(this);
  }

  init(board: Board, pointerLayer: Konva.Layer) {
    this.stage = board.stage;

    this.layer = pointerLayer;
    this.layer.add(this.circle);
    this.layer.add(this.outerCircle);
    this.circle.strokeWidth(1 / board.stage.scaleX());
    this.outerCircle.strokeWidth(1 / board.stage.scaleX());

    this.container = board.container;
    this.container.style.cursor = "none";

    this.visible = true;

    board.stage.on("pointermove.brushcursor", this.update.bind(this));
    board.container.addEventListener("pointerenter", this.enterHandler);
    board.container.addEventListener("pointerleave", this.leaveHandler);
  }

  deinit(board: Board, _pointerLayer: Konva.Layer) {
    this.circle.remove();
    this.outerCircle.remove();
    this.stage = undefined;
    this.layer = undefined;

    if (this.container) {
      this.container.style.cursor = "unset";
      this.container = undefined;
    }

    board.stage.off("pointermove.brushcursor");

    board.container.removeEventListener("pointerenter", this.enterHandler);
    board.container.removeEventListener("pointerleave", this.leaveHandler);
  }

  update() {
    if (!this.stage || !this.layer) {
      return;
    }
    const point = this.stage.getRelativePointerPosition();
    if (!point) {
      return;
    }
    this.circle.setPosition(point);
    this.outerCircle.setPosition(point);
    this.layer.batchDraw();
  }

  scale(scale: number) {
    this.circle.strokeWidth(1 / scale);
    this.outerCircle.strokeWidth(1 / scale);
    this.outerCircle.radius(this.circle.radius() + 1 / scale);
    this.stage?.batchDraw();
  }

  enter() {
    if (this.visible || !this.layer) {
      return;
    }
    this.visible = true;
    this.layer.add(this.circle);
    this.layer.add(this.outerCircle);
  }

  leave() {
    if (!this.visible) {
      return;
    }
    this.visible = false;
    this.circle.remove();
    this.outerCircle.remove();
  }

  setRadius(radius: number) {
    this.circle.radius(radius);
    if (!this.stage) {
      return;
    }
    this.outerCircle.radius(radius + 1 / this.stage.scaleX());
  }
}
