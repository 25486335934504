import React, { useCallback } from "react";
import { useStore } from "../../store";
import { Panel } from "../Panel";

import "./Eraser.scss";

export const EraserPanel: React.FunctionComponent = () => {
  const { strokeWidth, ...toolSettings } = useStore(
    (state) => state.toolSettings.eraser,
  );
  const setSettings = useStore((state) => state.setToolSetting);

  const setStrokeWidth = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const strokeWidth = parseInt(e.target.value);
      setSettings("eraser", {
        ...toolSettings,
        strokeWidth,
      });
    },
    [toolSettings, setSettings],
  );

  return (
    <Panel title="Eraser Props" className="eraser">
      <div className="inner">
        <input
          type="range"
          min="1"
          max="200"
          step="1"
          value={strokeWidth}
          onChange={setStrokeWidth}
        />{" "}
        <input
          type="number"
          min="1"
          max="200"
          step="1"
          onChange={setStrokeWidth}
          value={strokeWidth}
        />
      </div>
    </Panel>
  );
};
