import React, { useCallback, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";

export interface PasswordResetPageParams {
  token: string;
}

export type ResetResponse = { error: string } | { success: string };

export const PasswordResetPage: React.FunctionComponent = () => {
  const { token } = useParams<PasswordResetPageParams>();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState<string>();
  const [reset, setReset] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const submit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setError(undefined);

      if (!password.trim() || !passwordConfirm.trim()) {
        return;
      }

      if (!buttonRef.current) {
        return;
      }

      buttonRef.current.disabled = true;

      console.log({
        token,
        password,
        password_confirm: passwordConfirm,
      });

      const resp = await fetch("/api/reset", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          password,
          password_confirm: passwordConfirm,
        }),
      });

      buttonRef.current.disabled = false;

      let data: ResetResponse;
      try {
        data = await resp.json();
      } catch {
        setError("The server encountered an error, please try again.");
        return;
      }

      if ("error" in data) {
        setError(data.error);
      } else {
        setReset(true);
      }
    },
    [token, password, passwordConfirm],
  );

  return (
    <>
      {!reset ? (
        <form onSubmit={submit}>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.currentTarget.value)}
          />
          <button type="submit" ref={buttonRef}>
            Reset Password
          </button>
          {error && <div>{error}</div>}
        </form>
      ) : (
        <div>
          <b>Success!</b> your password has been reset.{" "}
          <Link to="/">Click here to login</Link>
        </div>
      )}
    </>
  );
};
