import Konva from "@greyboard/konva";

export interface Point {
  x: number;
  y: number;
}

export type Path = Point[];
export type StrokeCap = "butt" | "round" | "square";
export interface StrokeProps {
  width: number;
  colour: string;
  cap: StrokeCap;
  opacity?: number;
}

export type BaseShape = {
  id: string;
};

export type BrushStroke = {
  type: "brush-stroke";
  path: Path;
  props: StrokeProps;
} & BaseShape;

export type EraserStroke = Omit<BrushStroke, "type"> & {
  type: "eraser-stroke";
};

export type BrushShapes = BrushStroke | EraserStroke;
export type Shape = BrushStroke | EraserStroke;

export const getShape = (shape: Shape): Konva.Shape | null => {
  switch (shape.type) {
    case "brush-stroke":
      return new Konva.Line({
        id: shape.id,
        stroke: shape.props.colour,
        strokeWidth: shape.props.width,
        lineCap: shape.props.cap,
        points: shape.path.flatMap(({ x, y }) => [x, y]),
        tension: 0.5,
        globalCompositeOperation: "source-over",
        opacity: shape.props.opacity === undefined ? 1 : shape.props.opacity,
      });
    case "eraser-stroke":
      return new Konva.Line({
        id: shape.id,
        stroke: shape.props.colour,
        strokeWidth: shape.props.width,
        lineCap: shape.props.cap,
        points: shape.path.flatMap(({ x, y }) => [x, y]),
        tension: 0.5,
        globalCompositeOperation: "destination-out",
      });
  }
  return null;
};

export const updateShape = (shape: Shape, konvaShape: Konva.Shape) => {
  switch (shape.type) {
    case "brush-stroke":
    case "eraser-stroke":
      const line: Konva.Line = konvaShape as any;
      line.points(shape.path.flatMap(({ x, y }) => [x, y]));
      break;
  }
};
