import React, { useCallback } from "react";
import { useStore } from "../../store";
import { Panel } from "../Panel";

import { HexColorPicker } from "react-colorful";

import "./Brush.scss";

export const BrushPanel: React.FunctionComponent = () => {
  const toolSettings = useStore((state) => state.toolSettings.brush);
  const setSettings = useStore((state) => state.setToolSetting);
  const colours = useStore((state) => state.colours);
  const setColours = useStore((state) => state.setColours);

  const setStrokeWidth = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const strokeWidth = Math.abs(parseInt(e.target.value)) || 1;
      setSettings("brush", {
        ...toolSettings,
        strokeWidth,
      });
    },
    [toolSettings, setSettings],
  );

  const setOpacity = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const opacity = parseInt(e.target.value) / 100;
      setSettings("brush", {
        ...toolSettings,
        opacity,
      });
    },
    [toolSettings, setSettings],
  );

  const setColour = useCallback(
    (colour: string) => {
      setColours({
        ...colours,
        current: colour,
      });
    },
    [setColours, colours],
  );

  return (
    <Panel title="Brush Props" className="brush">
      <div className="inner">
        <HexColorPicker color={colours.current} onChange={setColour} />
        <div className="colour-history">
          {colours.history?.map((c) => (
            <div
              key={c}
              style={{ background: c }}
              onClick={() => setColour(c)}
            />
          ))}
        </div>
        <div>Stroke Width</div>
        <input
          type="range"
          min="1"
          max="200"
          step="1"
          value={toolSettings.strokeWidth}
          onChange={setStrokeWidth}
        />{" "}
        <input
          type="number"
          min="1"
          max="200"
          step="1"
          value={toolSettings.strokeWidth}
          onChange={setStrokeWidth}
        />
        <div>Opacity</div>
        <input
          type="range"
          min="1"
          max="100"
          step="1"
          value={toolSettings.opacity * 100}
          onChange={setOpacity}
        />
        <input
          type="number"
          min="1"
          max="100"
          step="1"
          value={Math.round(toolSettings.opacity * 100)}
          onChange={setOpacity}
        />
      </div>
    </Panel>
  );
};
