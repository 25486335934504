import React, { useMemo } from "react";
import { LoginPage } from "../pages/Login";
import { useStore } from "../store";

export const Auth: React.FunctionComponent = ({ children }) => {
  const sessionToken = useStore((state) => state.sessionToken);
  const expiresAt = useStore((state) => state.expiresAt);

  const authed: boolean = useMemo(() => {
    if (!sessionToken || !expiresAt) {
      return false;
    }
    return true;
  }, [sessionToken, expiresAt]);

  return <>{authed ? children : <LoginPage />}</>;
};
