import React, { useState } from "react";
import cs from "clsx";

import "./Panel.scss";
import { useStore } from "../store";

export interface PanelProps {
  title: React.ReactNode;
  onClose?: (closing: boolean) => void;
  onMinimise?: () => void;
  canMinimise?: boolean;
  className?: string;
  defaultMinimised?: boolean;
  hideOnDraw?: boolean;
  passthroughOnDraw?: boolean;
}

export const Panel: React.FunctionComponent<PanelProps> = ({
  canMinimise,
  className,
  children,
  defaultMinimised,
  onClose,
  onMinimise,
  // hideOnDraw,
  passthroughOnDraw,
  title,
}) => {
  const drawing = useStore((state) => state.drawing);

  const [open, setOpen] = useState<boolean>(
    defaultMinimised === undefined ? true : !defaultMinimised,
  );

  // const willHide = hideOnDraw === undefined ? true : hideOnDraw;
  const willPassthrough =
    passthroughOnDraw === undefined ? true : passthroughOnDraw;

  return (
    <div
      className={cs("panel", className, {
        minimised: !open,
        passthrough: drawing && willPassthrough,
      })}
    >
      <div className="panel-title">
        {title}{" "}
        {(canMinimise === undefined || canMinimise) && (
          <button
            className="minimise-button"
            onClick={onMinimise || (() => setOpen(!open))}
          >
            _
          </button>
        )}{" "}
        {onClose && <button className="close-button">X</button>}
      </div>
      {open && children}
    </div>
  );
};
