import React, { useCallback, useEffect, useState } from "react";
import cs from "clsx";
import { v4 as uuid } from "uuid";

import { useStore } from "../store";

import "./Layers.scss";
import { Panel } from "./Panel";
import { Layer, Shape } from "../board";

export const LayersPanel: React.FunctionComponent = () => {
  const board = useStore((state) => state.board);
  const [layers, setLayers] = useState<Layer[]>([]);
  const [selected, setSelected] = useState<string | undefined>();

  useEffect(() => {
    if (!board) {
      return;
    }

    const layersChanged = () => {
      setLayers([...board.layers].reverse());
    };

    const layerSelected = () => {
      setSelected(board.selectedLayer);
    };

    layersChanged();
    layerSelected();

    board.addListener("layersChanged", layersChanged);
    board.addListener("layerSelected", layerSelected);

    return () => {
      board.removeListener("layersChanged", layersChanged);
      board.removeListener("layerSelected", layerSelected);
    };
  }, [board]);

  const deleteLayer = useCallback(
    (id: string) => {
      if (!board) {
        return;
      }
      board.removeLayer(id);
    },
    [board],
  );
  const selectLayer = useCallback(
    (id: string) => {
      if (!board) {
        return;
      }
      board.selectLayer(id);
    },
    [board],
  );

  const newLayer = useCallback(() => {
    if (!board) {
      return;
    }
    board.addLayer({
      id: uuid(),
      name: `Layer ${board.layers.length + 1}`,
      children: [] as Shape[],
      visible: true,
    });
  }, [board]);

  return (
    <Panel className="layers" title="Layers">
      <div className="layers-list">
        {layers.map((l) => (
          <div
            key={l.id}
            className={cs("layer", { selected: l.id === selected })}
            onClick={() => selectLayer(l.id)}
          >
            {l.name} ({l.visible ? "visible" : "!visible"}){" "}
            <span
              onClick={(e) => {
                e.stopPropagation();
                deleteLayer(l.id);
              }}
            >
              (DELETE)
            </span>
          </div>
        ))}
      </div>
      <div className="layers-toolbar">
        <span onClick={newLayer}>+</span>
      </div>
    </Panel>
  );
};
