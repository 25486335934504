import React, { useCallback, useRef, useState } from "react";
import { useStore } from "../../store";

export type LoginResponse =
  | { error: string }
  | { success: true; session_token: string; expires_at: string };

export const LoginPage: React.FunctionComponent = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string>();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const setAuth = useStore((state) => state.setAuth);

  const login = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setError(undefined);

      if (!username.trim() || !password.trim()) {
        return;
      }
      if (!buttonRef.current) {
        return;
      }

      buttonRef.current.disabled = true;

      const resp = await fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
      buttonRef.current.disabled = false;
      let data: LoginResponse;
      try {
        data = await resp.json();
      } catch {
        setError("The server encountered an error, please try again.");
        return;
      }
      if ("error" in data) {
        setError(data.error);
      } else {
        setAuth(data.session_token, data.expires_at);
      }
    },
    [username, password, setAuth],
  );

  return (
    <form onSubmit={login}>
      <div>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.currentTarget.value)}
          placeholder="Username"
          id="username"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          placeholder="Password"
          id="password"
        />
        <button type="submit" ref={buttonRef} id="submit">
          Login
        </button>
      </div>
      {error && <div>{error}</div>}
    </form>
  );
};
