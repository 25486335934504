import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Board } from "../../components/Board/Board";
import { useStore } from "../../store";
import "./Board.scss";

export interface BotBoardPageParams {
  id: string;
}

export const BotBoardPage: React.FunctionComponent = () => {
  const { id } = useParams<BotBoardPageParams>();

  const board = useStore((state) => state.board);

  useEffect(() => {
    if (!board) {
      return;
    }

    (window as any).setBoardScale = board.setScale.bind(board);
    (window as any).setBoardPosition = board.setPosition.bind(board);
  }, [board]);

  return (
    <div className="board">
      <Board id={id} />
    </div>
  );
};
