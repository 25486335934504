import Konva from "@greyboard/konva";
import EventEmitter from "eventemitter3";

import { BrushSettings, getShape, Point, Shape, Tool, updateShape } from ".";

export interface Layer {
  id: string;
  name: string;
  visible: boolean;
  children: Shape[];
}

export interface Cursor {
  init: (board: Board, pointerLayer: Konva.Layer) => void;
  deinit: (board: Board, pointerLayer: Konva.Layer) => void;
  update: () => void;
  scale?: (scale: number) => void;
}

export interface BoardEvents {
  layerAdded: [Layer, Konva.Layer, boolean];
  layerSelected: [Layer, Konva.Layer, boolean];
  layerRemoved: [Layer, Konva.Layer, boolean];
  layersChanged: [Layer[], boolean];
  shapeAdded: [Shape, Layer, boolean];
  shapeRemoved: [Shape, Layer, boolean];
  drawingStart: [Shape, Layer];
  drawingUpdate: [Shape, Layer];
  drawingEnd: [Shape, Layer];
  colourUsed: [string];
}

export interface ToolSettings {
  brush: BrushSettings;
  eraser: BrushSettings;
}

class Pan {
  board: Board;
  isDragging: boolean;
  currentDrag: Point | undefined;

  constructor(board: Board) {
    this.board = board;
    this.isDragging = false;
  }

  init() {
    this.board.stage.on("pointerdown.pandrag", this.panDown.bind(this));
    this.board.stage.on("pointermove.pandrag", this.panMove.bind(this));
    this.board.stage.on("pointerup.pandrag", this.panUp.bind(this));
    document.addEventListener("keydown", this.spacePanDown);
    document.addEventListener("keyup", this.spacePanUp);
  }

  deinit() {
    this.board.stage.off(
      "pointerdown.pandrag pointermove.pandrag pointerup.pandrag",
    );
    this.board.stage.off("keydown.pandrag keyup.pandrag");
  }

  panDown(e: Konva.KonvaEventObject<PointerEvent>) {
    if (e.evt.button !== 1) {
      return;
    }
    this.isDragging = true;
    const pos = this.board.stage.getPointerPosition();
    if (!pos) {
      return;
    }
    this.currentDrag = pos;
  }

  spacePanDown = (e: KeyboardEvent) => {
    if (e.code !== "Space" || e.target instanceof HTMLInputElement) {
      return;
    }
    this.isDragging = true;
    const pos = this.board.stage.getPointerPosition();
    if (!pos) {
      return;
    }
    this.currentDrag = pos;
  };

  panMove() {
    if (!this.isDragging) {
      return;
    }
    if (!this.currentDrag) {
      this.currentDrag = this.board.stage.getPointerPosition() || undefined;
      return;
    }
    const pos = this.board.stage.getPointerPosition();
    if (!pos) {
      return;
    }
    const offsetX = pos.x - this.currentDrag.x;
    const offsetY = pos.y - this.currentDrag.y;
    this.board.stage.position({
      x: this.board.stage.x() + offsetX,
      y: this.board.stage.y() + offsetY,
    });
    this.currentDrag = pos;
    this.board.stage.batchDraw();
  }

  panUp() {
    if (!this.isDragging) {
      return;
    }
    this.isDragging = false;
    this.currentDrag = undefined;
  }

  spacePanUp = (e: KeyboardEvent) => {
    if (e.code !== "Space") {
      return;
    }
    if (!this.isDragging) {
      return;
    }
    this.isDragging = false;
    this.currentDrag = undefined;
  };
}

class Zoom {
  board: Board;
  scaleBy: number;

  constructor(board: Board, scaleBy: number = 1.1) {
    this.board = board;
    this.scaleBy = scaleBy;
  }

  init() {
    this.board.stage.on("wheel.zoom", this.zoom.bind(this));
  }

  zoom(e: Konva.KonvaEventObject<WheelEvent>) {
    e.evt.preventDefault();
    const oldScale = this.board.stage.scaleX();

    const pointer = this.board.stage.getPointerPosition();
    if (!pointer) {
      return;
    }

    const mousePointTo = {
      x: (pointer.x - this.board.stage.x()) / oldScale,
      y: (pointer.y - this.board.stage.y()) / oldScale,
    };

    let newScale =
      e.evt.deltaY < 0 ? oldScale * this.scaleBy : oldScale / this.scaleBy;

    if (newScale > 50) {
      newScale = 50;
    } else if (newScale < 0.1) {
      newScale = 0.1;
    }

    this.board.stage.scale({ x: newScale, y: newScale });

    this.board.stage.position({
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    });

    this.board.tool?.scale?.(newScale);

    this.board.stage.batchDraw();
  }
}

export class Board extends EventEmitter<BoardEvents> {
  id: string;
  stage: Konva.Stage;
  container: HTMLDivElement;
  layers: Layer[];
  selectedLayer?: string;
  pointerLayer: Konva.Layer;
  cursor?: Cursor;
  tool?: Tool;
  pan: Pan;
  zoom: Zoom;
  toolSettings: ToolSettings;
  tempShapes: Record<
    Shape["id"],
    {
      shape: Shape;
      konvaShape: Konva.Shape;
      updatedAt: Date;
    }
  >;

  constructor(id: string, el: HTMLDivElement) {
    super();
    this.id = id;
    this.stage = new Konva.Stage({ container: el });
    (window as any).stage = this.stage;
    this.container = el;
    this.layers = [];
    this.pointerLayer = new Konva.Layer();
    this.pointerLayer.listening(false);
    this.stage.add(this.pointerLayer);
    this.pan = new Pan(this);
    this.pan.init();
    this.zoom = new Zoom(this);
    this.zoom.init();

    this.toolSettings = {
      brush: {
        colour: "#000",
        strokeWidth: 10,
        opacity: 1,
      },
      eraser: {
        colour: "#000",
        strokeWidth: 10,
        opacity: 1,
      },
    };

    this.tempShapes = {};

    this.updateSize();
    window.addEventListener("resize", this.updateSize.bind(this));
  }

  addLayer(layer: Layer, broadcast: boolean = true) {
    if (this.getLayer(layer.id)) {
      console.warn("Layer with ID (%s) already exists", layer.id);
      return;
    }

    this.layers.push(layer);
    const klayer = new Konva.Layer({
      id: layer.id,
      name: layer.name,
      visible: layer.visible,
    });
    klayer.listening(false);
    this.stage.add(klayer);

    this.pointerLayer.moveToTop();
    this.emit("layerAdded", layer, klayer, broadcast);
    this.emit("layersChanged", this.layers, broadcast);

    if (!this.selectedLayer) {
      this.selectLayer(layer.id);
    }

    if (layer.children.length) {
      klayer.add(
        ...(layer.children
          .map((s) => getShape(s))
          .filter((v) => v !== null) as any),
      );
    }
  }

  selectLayer(id: string, broadcast: boolean = true) {
    const layer = this.layers.find((v) => v.id === id);
    if (!layer) {
      return;
    }
    this.selectedLayer = id;
    this.emit("layerSelected", layer, this.getKonvaLayer(id), broadcast);
  }

  getLayer(id: string) {
    return this.layers.find((l) => l.id === id);
  }

  getKonvaLayer(id: string): Konva.Layer {
    return this.stage.findOne(`#${id}`);
  }

  getSelectedKonvaLayer(): Konva.Layer | undefined {
    if (!this.selectedLayer) {
      return;
    }
    return this.getKonvaLayer(this.selectedLayer);
  }

  getSelectedLayer() {
    return this.layers.find((l) => l.id === this.selectedLayer);
  }

  removeLayer(id: string, broadcast: boolean = true) {
    // Always have at least one layer
    if (this.layers.length < 2) {
      return;
    }
    const layerIndex = this.layers.findIndex((v) => v.id === id);
    if (layerIndex === -1) {
      return;
    }

    const layer = this.layers[layerIndex];
    const klayer = this.getKonvaLayer(id);

    const nextLayer = layerIndex >= 1 ? layerIndex - 1 : 1;
    if (layer.id === this.getSelectedLayer()?.id) {
      this.selectLayer(this.layers[nextLayer].id);
    }

    this.layers.splice(layerIndex, 1);
    klayer.remove();

    this.emit("layerRemoved", layer, klayer, broadcast);
    this.emit("layersChanged", this.layers, broadcast);
  }

  addShape(layerId: string, shape: Shape, broadcast: boolean = true) {
    if (this.getShape(shape.id)) {
      console.warn("Shape with ID (%s) already exists", shape.id);
      return;
    }

    const layer = this.layers.find((l) => l.id === layerId);
    const klayer = this.getKonvaLayer(layerId);
    if (!layer || !klayer) {
      console.warn(
        "Tried to add a shape to a non-existent layer with ID:",
        layerId,
      );
      return;
    }

    const kshape = getShape(shape);
    if (!kshape) {
      return;
    }

    layer.children.push(shape);
    klayer.add(kshape);
    this.emit("shapeAdded", shape, layer, broadcast);
  }

  removeShape(layerId: string, shapeId: string, broadcast: boolean = true) {
    const layer = this.getLayer(layerId);
    const shape = this.getShape(shapeId);
    if (!layer || !shape) {
      return;
    }
    const shapeIndex = layer.children.findIndex((s) => s.id === shapeId);
    if (shapeIndex === -1) {
      return;
    }
    const kshape = this.stage.findOne(`#${shapeId}`);
    if (kshape) {
      kshape.remove();
    }
    layer.children.splice(shapeIndex, 1);

    this.emit("shapeRemoved", shape, layer, broadcast);
  }

  getShape(id: string) {
    for (const layer of this.layers) {
      const shape = layer.children.find((s) => s.id === id);
      if (shape) {
        return shape;
      }
    }
    return;
  }

  updateSize() {
    this.stage.size({
      height: this.container.clientHeight,
      width: this.container.clientWidth,
    });
  }

  setTool(tool: Tool | undefined) {
    if (this.tool) {
      this.tool.deinit(this);
    }
    this.tool = tool;
    if (this.tool) {
      this.tool.init(this, this.toolSettings);
    }
  }

  updateToolSettings(settings: ToolSettings) {
    this.toolSettings = settings;
    this.tool?.updateToolSettings(settings);
  }

  addTempShape(layerId: string, shape: Shape) {
    const konvaShape = getShape(shape);
    if (!konvaShape) {
      return;
    }

    const klayer = this.getKonvaLayer(layerId);
    if (!klayer) {
      return;
    }

    this.tempShapes[shape.id] = {
      shape,
      konvaShape,
      updatedAt: new Date(),
    };

    klayer.add(konvaShape);
    this.stage.batchDraw();
  }

  updateTempShape(_layerId: string, shape: Shape) {
    if (!this.tempShapes[shape.id]) {
      return;
    }
    const { konvaShape } = this.tempShapes[shape.id];
    updateShape(shape, konvaShape);
    this.tempShapes[shape.id] = {
      shape,
      konvaShape,
      updatedAt: new Date(),
    };
    this.stage.batchDraw();
  }

  removeTempShape(_layerId: string, { id }: Shape) {
    if (!(id in this.tempShapes)) {
      return;
    }
    const { konvaShape } = this.tempShapes[id];
    konvaShape.destroy();
    delete this.tempShapes[id];
    this.stage.batchDraw();
  }

  setPosition(point: Point) {
    this.stage.position(point);
  }

  setScale(scale: number) {
    this.stage.scale({ x: scale, y: scale });
  }
}
