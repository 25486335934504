import React, { useEffect, useRef, useState } from "react";

import { Board as BoardClass } from "../../board";
import { Remote } from "../../remote";
import { useStore } from "../../store";

import "./Board.scss";

export interface BoardProps {
  id: string;
}

export const Board: React.FunctionComponent<BoardProps> = ({ id }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [joined, setJoined] = useState<boolean>(false);

  const board = useStore((store) => store.board);
  const setBoard = useStore((store) => store.setBoard);
  const remote = useStore((store) => store.remote);
  const setRemote = useStore((store) => store.setRemote);
  const toolSettings = useStore((store) => store.toolSettings);
  const setToolSetting = useStore((store) => store.setToolSetting);
  const colours = useStore((store) => store.colours);
  const setColours = useStore((store) => store.setColours);
  const setDrawing = useStore((store) => store.setDrawing);
  const sessionToken = useStore((store) => store.sessionToken);
  const clearAuth = useStore((store) => store.clearAuth);

  useEffect(() => {
    if (!containerRef.current || !sessionToken) {
      return;
    }

    const board = new BoardClass(id, containerRef.current);
    setBoard(board);
    const remote = new Remote(
      board,
      sessionToken,
      () => {
        (window as any).boardConnected = true;
        setJoined(true);
      },
      (e: any) => {
        if (e?.data?.reason === "auth") {
          clearAuth();
        }
      },
      () => {
        (window as any).boardConnected = false;
        setJoined(false);
      },
    );
    setRemote(remote);

    return () => {
      remote.close();
    };
  }, [id, containerRef, setBoard, sessionToken, clearAuth, setRemote]);

  useEffect(() => {
    if (!remote) {
      return;
    }
    const handler = (e: KeyboardEvent) => {
      if (!remote) {
        return;
      }
      if (e.target instanceof HTMLInputElement) {
        return;
      }
      if (e.code === "KeyZ") {
        if (e.shiftKey && e.ctrlKey) {
          e.preventDefault();
          remote.redo();
        } else if (e.ctrlKey) {
          e.preventDefault();
          remote.undo();
        }
      }
      if (e.code === "KeyY" && e.ctrlKey) {
        e.preventDefault();
        remote.redo();
      }
    };
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [remote]);

  useEffect(() => {
    if (!board) {
      return;
    }

    const start = () => setDrawing(true);
    const end = () => setDrawing(false);

    board.on("drawingStart", start);
    board.on("drawingEnd", end);

    return () => {
      board.off("drawingStart", start);
      board.off("drawingEnd", end);
    };
  }, [board, setDrawing, setColours]);

  useEffect(() => {
    if (!board) {
      return;
    }

    const colourUsed = (colour: string) => {
      let history = [...(colours.history || [])];
      history = history.filter((c) => c !== colour);
      history = [colour, ...history].slice(0, 10);
      setColours({ ...colours, history });
    };

    board.on("colourUsed", colourUsed);

    return () => {
      board.off("colourUsed", colourUsed);
    };
  }, [board, colours, setColours]);

  useEffect(() => {
    if (!board) {
      return;
    }

    board.updateToolSettings(toolSettings);
  }, [board, toolSettings]);

  useEffect(() => {
    const brush = { ...toolSettings.brush };
    if (brush.colour !== colours.current) {
      brush.colour = colours.current;
      setToolSetting("brush", brush);
    }
  }, [toolSettings, setToolSetting, colours]);

  return (
    <>
      <div className="board-container" ref={containerRef} />
      {!joined && (
        <div className="board-loading">
          <div className="loading-message">Connecting...</div>
        </div>
      )}
    </>
  );
};
