import { io, Socket } from "socket.io-client";
import { Board, Layer, Shape } from "../board";

const debounce = <T extends Function>(func: T, ms: number): T => {
  let timer: number | undefined;
  let params: any[] | undefined;

  return ((...args: any[]) => {
    params = args;
    if (!timer) {
      timer = setTimeout(() => {
        func.apply(this, params);
        timer = undefined;
        params = undefined;
      }, ms) as any;
    }
  }) as any;
};

export class Remote {
  socket: Socket;
  board: Board;

  constructor(
    board: Board,
    authToken: string,
    onJoined: () => void,
    onAuthError: (e: Error) => void,
    onDisconnect: () => void,
  ) {
    this.socket = io(process.env.REACT_APP_SOCKETIO_BASE!, {
      transports: ["websocket"],
      auth: { token: authToken },
    });
    this.board = board;

    this.socket.on("connect", this.onConnect.bind(this));
    this.socket.on("connect_error", onAuthError);
    this.socket.on("disconnect", onDisconnect);
    this.socket.on(`board:layer:add`, this.onRemoteLayerAdded.bind(this));
    this.socket.on(`board:layer:remove`, this.onRemoteLayerRemoved.bind(this));
    this.socket.on(`board:shape:add`, this.onRemoteShapeAdded.bind(this));
    this.socket.on(`board:shape:remove`, this.onRemoteShapeRemoved.bind(this));
    this.socket.on(`board:layers`, this.onRemoteLayers.bind(this));
    this.socket.on("board:draw:start", this.onRemoteDrawStarted.bind(this));
    this.socket.on("board:draw:update", this.onRemoteDrawUpdated.bind(this));
    this.socket.on("board:draw:end", this.onRemoteDrawEnded.bind(this));
    this.socket.once("board:joined", onJoined);

    this.board.on("layerAdded", this.onLocalLayerAdded);
    this.board.on("layerRemoved", this.onLocalLayerRemoved);
    this.board.on("shapeAdded", this.onLocalShapeAdded);
    this.board.on("shapeRemoved", this.onLocalShapeRemoved);
    this.board.on("drawingStart", this.onLocalDrawStart);
    this.board.on("drawingUpdate", this.onLocalDrawUpdate);
    this.board.on("drawingEnd", this.onLocalDrawEnd);
  }

  undo() {
    this.socket.emit("board:undo", this.board.id);
  }

  redo() {
    this.socket.emit("board:redo", this.board.id);
  }

  onConnect() {
    this.socket.emit("joinBoard", this.board.id);
  }

  onRemoteLayers(_boardId: string, layers: Layer[]) {
    layers.map((l) => this.board.addLayer(l, false));
  }

  onRemoteLayerAdded(_boardId: string, layer: Layer) {
    this.board.addLayer(layer, false);
  }

  onRemoteLayerRemoved(_boardId: string, layerId: string) {
    this.board.removeLayer(layerId, false);
  }

  onRemoteShapeAdded(_boardId: string, layerId: string, shape: Shape) {
    this.board.addShape(layerId, shape, false);
  }

  onRemoteShapeRemoved(_boardId: string, layerId: string, shapeId: string) {
    this.board.removeShape(layerId, shapeId, false);
  }

  onRemoteDrawStarted(_boardId: string, layerId: string, shape: Shape) {
    this.board.addTempShape(layerId, shape);
  }

  onRemoteDrawUpdated(_boardId: string, layerId: string, shape: Shape) {
    this.board.updateTempShape(layerId, shape);
  }

  onRemoteDrawEnded(_boardId: string, layerId: string, shape: Shape) {
    this.board.removeTempShape(layerId, shape);
  }

  onLocalLayerAdded = (layer: Layer, _: any, broadcast: boolean) => {
    if (!broadcast) {
      return;
    }
    this.socket.emit(`board:layer:add`, this.board.id, layer);
  };

  onLocalLayerRemoved = (layer: Layer, _: any, broadcast: boolean) => {
    if (!broadcast) {
      return;
    }
    this.socket.emit(`board:layer:remove`, this.board.id, layer.id);
  };

  onLocalShapeAdded = (shape: Shape, layer: Layer, broadcast: boolean) => {
    if (!broadcast) {
      return;
    }
    this.socket.emit(`board:shape:add`, this.board.id, layer.id, shape);
  };

  onLocalShapeRemoved = (shape: Shape, layer: Layer, broadcast: boolean) => {
    if (!broadcast) {
      return;
    }
    this.socket.emit(`board:shape:remove`, this.board.id, layer.id, shape.id);
  };

  onLocalDrawStart = (shape: Shape, layer: Layer) => {
    this.socket.emit(`board:draw:start`, this.board.id, layer.id, shape);
  };

  updateDebounced = debounce((shape: Shape, layer: Layer) => {
    this.socket.emit(`board:draw:update`, this.board.id, layer.id, shape);
  }, 1000 / 60);

  onLocalDrawUpdate = (shape: Shape, layer: Layer) => {
    // this.socket.emit(`board:draw:update`, this.board.id, layer.id, shape);
    this.updateDebounced(shape, layer);
  };

  onLocalDrawEnd = (shape: Shape, layer: Layer) => {
    this.socket.emit(`board:draw:end`, this.board.id, layer.id, shape);
  };

  close() {
    this.socket.close();

    this.board.off("layerAdded", this.onLocalLayerAdded);
    this.board.off("layerRemoved", this.onLocalLayerAdded);
    this.board.off("shapeAdded", this.onLocalShapeAdded);
    this.board.off("shapeRemoved", this.onLocalShapeRemoved);
    this.board.off("drawingStart", this.onLocalDrawStart);
    this.board.off("drawingUpdate", this.onLocalDrawUpdate);
    this.board.off("drawingEnd", this.onLocalDrawEnd);
  }
}
