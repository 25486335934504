import create from "zustand";
import { persist } from "zustand/middleware";
import { Board, ToolSettings } from "./board";
import { Remote } from "./remote";

export interface Colours {
  current: string;
  history: string[];
}

export interface StoreState {
  board?: Board;
  setBoard: (board: Board) => void;
  remote?: Remote;
  setRemote: (remote: Remote) => void;
  colours: Colours;
  setColours: (colour: Colours) => void;
  toolSettings: ToolSettings;
  setToolSetting: <T extends keyof ToolSettings>(
    name: T,
    settings: ToolSettings[T],
  ) => void;
  drawing: boolean;
  setDrawing: (drawing: boolean) => void;
  setAuth: (sessionToken: string, expiresAt: string) => void;
  clearAuth: () => void;
  sessionToken: string | undefined;
  expiresAt: Date | undefined;
}

export const useStore = create<StoreState>(
  persist(
    (set, get) => ({
      setBoard: (board) => set({ board }),
      setRemote: (remote) => set({ remote }),
      colours: {
        current: "#000",
        history: ["#000", "#F00"],
      },
      setColours: (colours) => set({ colours }),
      toolSettings: {
        brush: {
          colour: "#000",
          strokeWidth: 20,
          opacity: 1,
        },
        eraser: {
          colour: "#000",
          strokeWidth: 20,
          opacity: 1,
        },
      },
      setToolSetting: (name, settings) =>
        set({ toolSettings: { ...get().toolSettings, [name]: settings } }),
      drawing: false,
      setDrawing: (drawing) => set({ drawing }),
      setAuth: (sessionToken, expires) => {
        const formatted = expires + "Z";
        const expiresAt = new Date(Date.parse(formatted));
        set({ sessionToken, expiresAt });
      },
      clearAuth: () => set({ sessionToken: undefined, expiresAt: undefined }),
      sessionToken: undefined,
      expiresAt: undefined,
    }),
    {
      name: "greyboard",
      blacklist: ["board", "drawing", "remote"],
    },
  ),
);
